import pagesAndPermissions from '@/main/pages/pagesAndPermissions'
import primeOpinionPagesAndPermissions from '@/main/pages/primeOpinionPages'
import fiveSurveysPagesAndPermissions from '@/main/pages/fiveSurveysPages'
import primeSamplePagesAndPermissions from '@/main/pages/primeSamplePages'
import heyCashPagesAndPermissions from '@/main/pages/heyCashPages'
import topSurveysPagesAndPermissions from '@/main/pages/topSurveysPages'
import { getFilteredRoutes } from './route-utils'

const APP_TYPE = process.env.VUE_APP_TYPE || 'default'
const filteredRoutes = getFilteredRoutes(APP_TYPE)

// Create a flat list of allowed route names
const allowedRouteNames = filteredRoutes.map((route) => route.name)

function filterPagesByRoutes (pages) {
  return pages
    .filter((page) => {
      // Include the page if it matches an allowed route
      const pageName = page.route ?? page.sub_permissions?.[0]?.id
      if (pageName && allowedRouteNames.includes(pageName)) {
        return true
      }

      // Include children if any of them match the allowed routes
      if (page.children) {
        page.children = filterPagesByRoutes(page.children) // Recursive call
        return page.children.length > 0 // Keep the page if it has any allowed children
      }

      return false // Exclude the page if it doesn't match any allowed routes
    })
    .map((page) => ({
      ...page,
      // Ensure children are filtered recursively
      children: page.children ? filterPagesByRoutes(page.children) : undefined
    }))
}

function getPageAndPermission (pagesAndPermissions) {
  // implemented stack instead of recursive function to avoid stack overflow
  const returnData = []
  const stack = [...pagesAndPermissions]

  while (stack.length) {
    const item = stack.pop()

    if (item.children) {
      stack.push(...item.children)
    } else {
      returnData.push(item)
    }
  }
  return returnData
}

// Filter `pagesAndPermissions` based on filtered routes
const allPages = [
  ...pagesAndPermissions,
  ...primeOpinionPagesAndPermissions,
  ...fiveSurveysPagesAndPermissions,
  ...primeSamplePagesAndPermissions,
  ...heyCashPagesAndPermissions,
  ...topSurveysPagesAndPermissions
]

const filteredPages = filterPagesByRoutes(allPages)

// Flatten the filtered pages
export default getPageAndPermission(filteredPages)
